:root {
  --bg: #fff;
  --primary: #fff;
  --accent: #57db36;
  --accent-darker: #4ac02d;
  --text-color: #fff;
  --text-color-2: #bebebe;
  --text-color-dark: #000;
  --text-color-dark-2: #333;

  --lora: "Lora";
  --source-sans-pro: "Source Sans Pro";
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: var(--bg);
  font-family: "Segoe UI";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 768px) {
/*   html {
    font-size: 55%;
  } */
  html,
  body {
    overflow: auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url("./assets/fonts/source-sans-pro-v14-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/source-sans-pro-v14-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    url("./assets/fonts/source-sans-pro-v14-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/source-sans-pro-v14-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url("./assets/fonts/source-sans-pro-v14-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/source-sans-pro-v14-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lora-regular - latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./assets/fonts/lora-v16-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/lora-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lora-500 - latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./assets/fonts/lora-v16-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/lora-v16-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lora-700 - latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./assets/fonts/lora-v16-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/lora-v16-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lora-600 - latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./assets/fonts/lora-v16-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/lora-v16-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
