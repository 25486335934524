.container {
  .intro_section {
    max-width: 800px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    li {
      display: grid;
      grid-template-areas:
        "img title"
        "img desc";
      column-gap: 32px;
      row-gap: 16px;
      margin-top: 80px;

      &:first-child {
        margin-top: 0;
      }

      img {
        grid-area: img;
        height: 80px;
        width: 80px;
      }
      h1 {
        grid-area: title;
        font-size: 3.2rem;
      }
      p {
        grid-area: desc;
        font-size: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .container {
    text-align: center;
    .intro_section {
      li {
        grid-template-areas:
          "img"
          "title"
          "desc";
        margin-top: 40px;
        row-gap: 8px;

        img {
          margin: 0 auto;
          margin-top: 20px;
          height: 50px;
          width: 50px;
        }
        h1 {
          font-size: 1.8rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .container {
    text-align: center;
    .intro_section {
      li {
        grid-template-areas:
          "img"
          "title"
          "desc";
        margin-top: unset;
        margin-top: 16px;

        &:first-child {
          margin-top: unset;
        }
        img {
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .container {
    text-align: unset;
    .intro_section {
      max-width: 600px;
      height: 100vh;

      li {
        column-gap: 16px;
        row-gap: 4px;
        margin-top: 16px;
        grid-template-areas:
          "img title"
          "img desc";

        img {
          margin-top: 0;
          height: 60px;
          width: 60px;
        }
        h1 {
          font-size: 2rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
}
