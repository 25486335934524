.parent {
  /* overflow: visible; */
  /* scroll-snap-type: mandatory; */
  /* scroll-snap-type: y mandatory; */
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;

  .child {
    /* scroll-snap-align: start; */
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
    height: 100vh;
  }
}
