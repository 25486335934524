.content {
  height: 75vh;

  background-position: 50% 40%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #333;
  background-attachment: fixed;

  color: var(--text-color);

  position: relative;

  header {
    position: absolute;
    top: 84px;
    transform: translateY(-30%);
    left: 0;
    right: 0;
    margin: auto;
    padding: 48px auto;
    h1 {
      font-size: 4.8rem;
      font-weight: bold;
      line-height: 60px;
      max-width: 640px;
      /* color: black; */
    }
    p {
      font-size: 2rem;
      font-weight: 600;
      margin: 16px 0 32px 0;
      max-width: 640px;
      font-family: var(--source-sans-pro);
    }
  }
}

.cards {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .card {
    transform: translateY(-50%);
    padding: 24px;
    height: 200px;
    width: 380px;
    background-color: #241c1b;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.6);
    border-radius: 8px;

    background-repeat: no-repeat;
    background-size: cover;

    display: grid;
    grid-template-areas:
      "text"
      "link";
    grid-template-rows: auto min-content;

    text-align: center;

    h1 {
      grid-area: text;
      font-size: 2.8rem;
      font-weight: 500;
      color: var(--text-color);
      margin: 0 auto;
      max-width: 200px;
    }
    .button {
      grid-area: link;
      width: 72px;
      height: 48px;
      margin: 0 auto;
      border-radius: 16px;
      background-color: var(--accent);
      color: white;
      font-weight: bold;
      cursor: pointer;

      img {
        margin: 4px 0 0 4px;
      }
      li {
        list-style: none;
        padding: 12px 0;
        font-size: 1.6rem;
      }
    }
  }
}

@media only screen and (max-width: 1240px) {
  .content {
    height: calc(100vh - 60px);
  }
  .cards {
    padding-top: 48px;
    .card {
      transform: translateY(-250%);
    }
  }
}

@media only screen and (max-width: 900px) {
  .content {
    background-position: 10% 20%;
    header {
      transform: translateY(-35%);
      h1 {
        font-size: 3.4rem;
        line-height: 50px;
      }
    }
  }

  .cards {
    padding-top: 48px;
    .card {
      transform: translateY(-450%);
      height: 125px;
      width: 275px;
      padding: 16px;

      h1 {
        font-size: 2.2rem;
      }

      .button {
        transform: scale(0.8);
      }
    }
  }
}

@media only screen and (max-width: 900px) and (max-height: 700px) {
  .content {
    header {
      transform: translateY(-70%);
    }
  }
}

@media only screen and (max-height: 750px) {
  .content {
    header {
      h1 {
        font-size: 2.8rem;
        line-height: 40px;
      }
    }
  }
}

@media only screen and (max-height: 680px) and (orientation: portrait) {
  .cards {
    padding-top: 48px;
    .card {
      transform: translateY(-420%);
    }
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .content {
    height: calc(100vh - 84px);

    header {
      top: 50px;

      h1 {
        max-width: 100vw;
      }
    }
  }

  .cards {
    padding-top: 48px;
    .card {
      transform: translateY(-200%);
      height: 125px;
      width: 225px;
      padding: 16px;

      .button {
        transform: scale(0.8);
        grid-area: link;
        width: 60px;
        height: 40px;
        margin: 0 auto;
        li {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) and (max-height: 750px) and (orientation: landscape) {
  .content {
    header {
      top: 70px;
    }
  }
  .cards {
    .card {
      width: 215px;
    }
  }
}

@media only screen and (max-width: 670px) and (orientation: landscape) {
  .cards {
    .card {
      width: 180px;
    }
  }
}
