.container {
  display: grid;
  grid-template-areas: "content";

  .bg {
    grid-area: content;
    background-repeat: no-repeat;
    background-size: cover;

    height: 100vh;

    -webkit-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    -ms-filter: blur(8px);
    filter: blur(8px);
  }

  .pricing {
    grid-area: content;
    z-index: 1;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      max-width: 1200px;

      .title {
        font-size: 6rem;
        color: white;
        margin-bottom: 64px;
      }

      .section {
        display: grid;
        grid-template-areas:
          "card1 text"
          "card2 text";
        grid-template-columns: 32% auto;
        gap: 20px;
        /* margin-top: 50px; */

        .card {
          background-color: rgba(255, 255, 255, 0.65);
          border-radius: 8px;
          padding: 16px;
          text-align: center;
          /* height: 280px;
          width: 360px; */

          h1 {
            font-size: 2.4rem;
          }
          h2 {
            font-size: 2rem;
          }
          h1,
          h2 {
            font-weight: 600;
          }

          button {
            padding: 8px 32px;
            background-color: rgb(65, 255, 65);
            border-radius: 8px;
            color: white;
            margin-top: 12px;
          }
        }

        .text {
          grid-area: text;
          padding: 32px;
          border-radius: 8px;
          background-color: rgba(0, 0, 0, 0.4);

          font-size: 2.8rem;
          color: white;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .pricing {
      .container {
        max-height: 100vh;
        padding: 16px;

        .title {
          font-size: 2.8rem;
          text-align: center;
          margin-bottom: 32px;
        }

        .section {
          grid-template-areas:
            /* "text" */
            "card1"
            "card2";
          grid-template-columns: auto;
          margin-top: 8px;
          margin-top: 32px;
          .card {
            /* max-width: 280px;
            max-height: 100px; */
            img {
              max-width: 120px;
            }
            h1 {
              font-size: 2rem;
            }
            h2 {
              font-size: 1.8rem;
            }
          }

          .text {
            display: none;
            font-size: 2rem;
            padding: 16px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 900px) and (orientation: landscape) {
    .pricing {
      .container {
        .title {
          font-size: 3.4rem;
          margin-bottom: 24px;
        }

        .section {
          grid-template-areas: "card1 card2";
          grid-template-columns: auto;
          width: 600px;
          .card {
            img {
              max-width: 120px;
            }
            h1 {
              font-size: 1.8rem;
            }
            h2 {
              font-size: 1.6rem;
            }
          }

          .text {
            display: none;
          }
        }
      }
    }
  }
}
