nav {
  background-color: white;
  /* position: fixed; */
  z-index: 9;
  width: 100%;

  font-family: var(--source-sans-pro);

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 84px;

    .logo {
      vertical-align: middle;
      /* max-height: 40px; */
      max-height: 70px;
      cursor: pointer;
    }

    ul {
      list-style: none;
      margin: 0 auto 0 32px;
      padding: 0;

      /* .selected {
        color: var(--text-color-dark);
        li {
          color: var(--text-color-dark);

          &:after {
            width: 60%;
          }
        }
      } */

      li {
        display: inline-block;
        color: var(--text-color-dark-2);
        font-size: 1.6rem;
        cursor: pointer;
        padding: 16px;
        margin: 0 4px;
        border-radius: 4px;
        position: relative;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          content: "";
          background: var(--text-color-dark);
          height: 2px;
        }

        &:hover:after {
          width: 60%;
        }
      }
    }

    ul,
    ul li,
    ul li:after {
      transition: all 0.5s;
    }

    .login {
      padding: 10px 8px;
      margin-right: 24px;
      font-size: 1.4rem;
      font-weight: 600;
    }

    .cta {
      color: var(--text-color-dark);
      background-color: var(--accent);
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 4px;
      font-size: 1.6rem;

      &:hover {
        background-color: var(--accent-darker);
      }
    }

    button {
      font-family: var(--source-sans-pro);
    }

    .mobileMenu {
      display: none;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      .bar {
        height: 4px;
        width: 36px;
        margin: 4px 0;
        border-radius: 2px;
        background-color: var(--text-color-dark);

        &:first-child {
          width: 60%;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .container {
      display: grid;
      grid-template-areas:
        "logo . menu"
        "ul ul ul";
      grid-template-rows: 60px auto;
      min-height: 60px;

      .logo {
        grid-area: logo;
        max-height: 48px;
      }

      ul {
        grid-area: ul;
        display: none;
        margin: 0;

        .selected {
          li:after {
            width: 20%;
          }
        }

        li {
          display: block;
          text-align: center;
          padding: 20px;
          width: 100%;
          margin: 8px auto;
          font-weight: 600;

          &:hover:after {
            display:none;
          }
        }
      }

      .login {
        display: none;
      }

      .cta {
        display: none;
      }

      .mobileMenu {
        grid-area: menu;
        display: block;
        float: right;
      }

      .active {
        display: block;
      }
    }
  }
}
